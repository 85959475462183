
import { defineComponent, computed, defineEmits, ref } from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";
import { useStore } from "vuex";
import moment from "moment";
import { Actions } from "@/store/enums/StoreEnums";
import {UserType} from "@/types/UserType";
import { useRoute } from "vue-router";
export interface Feed {
  id: number;
  user: User;
  headline: string;
  type: string;
  subject_type: string;
  subject_id: number;
  is_fixed: number;
  module_id: number;
  subject: {
    id: number;
    name: string;
    description: string;
    dificulty: string;
    is_freemium: boolean;
    os_name: string;
    release_at: string;
    created_at: string;
    updated_at: string;
    type: string;
  };
  reaction: {
    current_user_reaction: string;
    eye: number;
    fire: number;
    like: number;
    love: number;
  };
  comments: Comment[];
  created_at: string;
  updated_at: string;
}

export interface User {
  id: number;
  name: string;
  nick: string;
  bio: string;
  xp: number;
  level: number;
  avatar: string;
  is_admin: boolean;
  user_patent: {
    name: string;
    icon: string;
    value: number;
  }
}

export interface Comment {
  id: number;
  message: string;
  reactions: {
    current_user_reaction: string;
    eye: number | string | undefined;
    fire: number | string | undefined;
    like: number | string | undefined;
    love: number | string | undefined;
  };
  user: User;
  created_at: string;
}

export default defineComponent({
  name: "widget-2",
  data() {
    return {
      current_comment: '',
      current_comment_focus: false,
    }
  },
  props: {
    widgetClasses: String,
    user: Object as () => User,
    feed: Object as () => Feed,
    currentUser: Object as () => UserType,
    show_comment: Boolean,
    id: [Number, String],
    lessonId: {
      required: false,
      type: [Number, String]
    }
  },
  components: {
    Dropdown2,
  },
  setup(props, { emit }) {
    const route = useRoute();
    const store = useStore();
    const loading = computed(() => store.getters.getFeedLoading);
    const loading_comment = ref(false);
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));


    const comments_view = ref(false);
    if(props.show_comment){
      comments_view.value = true;      
    }

    const deleteHacktivityUserAuth = async (id: number | undefined) => {
      try {
        await store.dispatch(Actions.DELETE_HACKTIVITY, { id: id, lessonId: props?.lessonId });
        emit('hacktivity-delete', props.feed?.id);
      } catch (e) {
        console.log(e)
      }
    }

    const handleFixedHacktivity = async (id: number | undefined) => {
      try {
        await store.dispatch(Actions.FIXED_HACKTIVITY, { id: id, lessonId: props?.lessonId });
        emit('hacktivity-fixed', props.feed?.id);
      } catch (e) {
        console.log(e)
      }
    }
    const isFixedFalseFeed = computed(() => route.path !== '/club/feed/'+props.feed?.id);
    return {
      moment,
      store,
      loading,
      loading_comment,
      deleteHacktivityUserAuth,
      comments_view,
      handleFixedHacktivity,
      isFixedFalseFeed,
      currentLanguage
    }
  },
  methods: {
    async openComment(){
      console.log(this.comments_view)

      this.comments_view = this.comments_view ? false : true; 

      console.log(this.comments_view)
    },
    async comment() {
      if (this.loading_comment || !this.current_comment) {
        console.log("ESTÁ NO LOADING......")
        return;
      }
      this.loading_comment = true;
      console.log("ALTERANDO PARA LOADING.....")
      console.log(this.loading_comment)
      this.current_comment_focus = true;
      await this.store.dispatch(Actions.COMMENT, { id: this.feed?.id, message: this.current_comment });
      this.comments_view = true;

      this.current_comment_focus = false;
      this.current_comment = '';
      this.$emit('hacktivity-added', this.feed?.id);
      console.log("ALTERANDO PARA NÃO LOADING.....")
      this.loading_comment = false;
      console.log(this.loading_comment)
    },
    async react(type: string, react: string, id: number | undefined) {
      if (this.loading) {
        return;
      }
      if (this.feed && type == 'hacktivity') {

        if (react == this.feed.reaction.current_user_reaction) {
          if (this.feed.reaction[react] <= 0) {
            this.feed.reaction[react] = 0;
            this.feed.reaction.current_user_reaction = '';
          } else {
            this.feed.reaction[react] -= 1;
            this.feed.reaction.current_user_reaction = '';
          }

        } else {
          this.feed.reaction[react] += 1;
          this.feed.reaction[this.feed.reaction.current_user_reaction] -= 1;
          this.feed.reaction.current_user_reaction = react;
        }

        await this.store.dispatch(Actions.REACT, { type: type, react: react, id: id });
      } else if (this.feed?.comments && type == 'comment') {
        let current_comment = this.feed?.comments?.find((comment) => comment.id == id)

        if (current_comment && react == current_comment?.reactions.current_user_reaction) {


          if (current_comment.reactions[react] <= 0) {
            current_comment.reactions[react] = 0;
            current_comment.reactions['current_user_reaction'] = '';

          } else {

            current_comment.reactions[react] -= 1;
            current_comment.reactions.current_user_reaction = '';
          }

        } else {
          if (current_comment) {
            current_comment.reactions[react] += 1;
            current_comment.reactions[current_comment.reactions?.current_user_reaction] -= 1;
            current_comment.reactions.current_user_reaction = react;

          } else {

          }

        }

        await this.store.dispatch(Actions.REACT, { type: type, react: react, id: id });
      }
      else {


      }
    },
    async replyComment(comment) {
      // check if nick is not null
      if (!comment.user.nick) {
        this.current_comment = `@${comment.user.name} `;
      } else {
        this.current_comment = `@${comment.user.nick} `;
      }

      this.current_comment_focus = true;
      await this.$nextTick();
      // focus on the textarea element using ref CurrentComment
      // @ts-ignore
      this.$refs.CurrentComment.focus();

    },

    async deleteComment(comment) {
      if (this.loading) {
        return;
      }
      await this.store.dispatch(Actions.DELETE_COMMENT, { comment_id: comment.id, hacktivity_id: comment.commentable_id });
      this.$emit('hacktivity-added', this.feed?.id);
    },

  }
});
